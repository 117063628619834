<template>
  <headermodel/>
	<router-view ></router-view>
  <footermodel/>
</template>

<script>
import headermodel from './components/header.vue'
import footermodel from './components/footer.vue'
export default {
  name: 'App',
  components: {
    headermodel,
	footermodel
  }
}
</script>

<style>
html {
	padding: 0;
	margin: 0;
}
body{
	padding: 0;
	margin: 0;
	cursor: pointer;
}
#app{
	padding: 0;
	margin: 0;
}
a{
	text-decoration: none;
	color: #000;
}
</style>
