import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
		{
			path:'/',
			redirect:'/index',
			meta: {}
	    },
		{
			path:'/index',
			name:'index',
			component:()=>import('../views/index.vue')
		},
		{
			path:'/course',
			name:'course',
			component:()=>import('../views/course.vue')
		},
		{
			path:'/contactus',
			name:'contactus',
			component:()=>import('../views/contactus.vue')
		},
		{
			path:'/aboutus',
			name:'aboutus',
			component:()=>import('../views/aboutus.vue')
		},{
			path:'/support',
			name:'support',
			component:()=>import('../views/support.vue')
		}
	]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router