<template>
	<div class="footer">
		<div class="footerall">
			<div class="footer_content">
				<div class="footer_title">联系我们</div>
				<div>
					幻次元旨在用全新的数字化技术和商业模式拥抱全球合作伙伴，共同打造基于XR+AI的商业新图景，共迎新时代。
					<br />
					合作伙伴类型：
					教育娱乐、文旅文创、想跻身AI空间计算时代风口的所有伙伴
				</div>
				<div>
<!-- 					联系方式：
					康 18589078990（vx同号）
					章 17322371250（vx同号） -->
				</div>
			</div>
			<div class="footer_right">
				<div class="erwm1">
					<div>公众号二维码</div>
					<img src="https://tencent-fanversego-1300332380.cos.ap-guangzhou.myqcloud.com/fanfuture-web/images/qrcode_for_gh_8309cbaed02d_430.jpg" alt="" />
				</div>
				<div class="erwm1">
					<div>微信二维码</div>
					<img src="https://tencent-fanversego-1300332380.cos.ap-guangzhou.myqcloud.com/fanfuture-web/images/%E5%BE%AE%E4%BF%A1%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg" alt="" />
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style scoped>
	@media screen and (min-width: 901px) {
		.footer {
			margin-top: 50px;
			overflow: hidden;
			display: flex;
			justify-content: center;
			background-color: #409eff;
		}
		.footerall{
			width: 1000px;
			display: flex;
		}
		.footer_content {
			width: 100%;
			line-height: 40px;
			padding: 40px 0;
			color: white;
			
		}

		.footer_title {
			font-size: 22px;
			font-weight: 600;
		}
		.footer_right{
			width: 100px;
			height: 100px;
			margin-right: 200px;
			margin-top: 40px;
			display: flex;
		}
		.footer_right>div{
			margin-left: 30px;
			text-align: center;
		}
		.footer_right img{
			width: 100px;
			height: 100px;
		}
		.erwm1{
			line-height: 50px;
			color: #fff;
		}
	}

	@media screen and (max-width: 900px) {
		.footer {
			/* margin-top: 50px; */
			overflow: hidden;
			/* display: flex; */
			background-color: #409eff;
			font-size: 14px;
		}
		
		.footer_content {
			width: 100%;
			line-height: 30px;
			padding: 20px;
			color: white;
			box-sizing: border-box;
			padding-bottom: 0;
		}
		
		.footer_title {
			font-size: 18px;
			font-weight: 600;
		}
		.footer_right{
			margin: 20px;
			margin-top: 0px;
			text-align: right;
			font-size: 12px;
			display: flex;
			justify-content: flex-end;
		}
		.footer_right>div{
			margin-right: 10px;
		}
		.footer_right img{
			width: 70px;
			height: 70px;
		}
		.erwm1{
			line-height: 40px;
			color: #fff;
		}
	}
</style>