<template>
  <div class="header">
	<div  class="allheader">
	  <div class="moveheader">
	  	<div class="logo">
	  		<img class="logo" src="../assets/logo.png" alt="" />
	  	</div>
	  	<div class="movetitle">{{title}}</div>
	  	<div class="movetab" @click="changeshowlist">
	  		{{showlist?'收起':'更多'}}
	  	</div>
	  </div>
	  <div :class="showlist? 'tablist zhangkai' :'tablist shouqi'">
	  	<div v-for="(item) in navigationList" >
	  		<router-link :class="$route.path==item.src?'selecta':''" :to="item.src"> {{item.title}}</router-link>
	  	</div>
	  </div>
	</div>
	
  </div>
</template>

<script>
	export default{
		data(){
			return{
				showlist:false,
				navigationList:[{
					title:'首页',
					src:'/index'
				},{
					title:'课程介绍',
					src:'/course'
				}
				,{
					title:'运营支持',
					src:'/support'
				}
				,{
					title:'关于我们',
					src:'/aboutus'
				},]
			} 
		},
		computed:{
			title(){
				return this.navigationList.filter(item=>this.$route.path==item.src)[0]?.title
			}
		},
		methods:{
			changeshowlist(){
				this.showlist = !this.showlist
			},
			gorouter(){
				
			}
		}
	}
</script>

<style scoped>
	@media screen and (min-width: 901px) {
		.header{
			position: sticky;
			top: 0;
			z-index: 100;
			width: 100vw;
			height: 80px;
			box-shadow: 0 2px 4px rgba(0,0,0,.0784313725);
			display: flex;
			justify-content: center;
			background-color: white;
		}
		.allheader{
			width: 1000px;
			line-height: 80px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
		}
		.logo{
			width: 150px;
			height: 50px;
		}
		.movetitle{
			display: none;
		}
		.movetab{
			display: none;
		}
		.tablist{
			display: flex;
		}
		.tablist>div{
			margin-right: 30px;
			box-sizing: border-box;
			
		}
		.tablist>div>a{
			/* margin-left: 40px; */
			width: 100px;
			text-align: right;
			padding: 20px;
			padding-left: 0;
			padding-right: 0;
		}
		.tablist>div>a:hover{
			color:#409eff;
			border-bottom: 2px solid #409eff;
		}
		.selecta{
			color:#409eff;
			border-bottom: 2px solid #409eff;
		}
	}
	@media screen and (max-width: 900px) {
		.header{
			position: sticky;
			top: 0;
			background-color: white;
			z-index: 100;
		}
		.moveheader{
			width: 100vw;
			height: 60px;
			line-height: 60px;
			
			padding:0 20px ;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			/* align-items: center; */
			box-shadow: 0 2px 4px rgba(0,0,0,.0784313725);
			cursor: pointer;
		}
		.logo{
			width: 80px;
			height: 30px;
			vertical-align: middle;
		}
		.movetab{
			width: 80px;
			height: 30px;
			text-align: right;
		}
		.movetitle{
			font-size: 18px;
			font-weight: 600;
			align-items: center;
		}
		.zhangkai{
			height:160px;
			overflow: hidden;
		}
		.shouqi{
			height:0px;overflow: hidden;
		}
		.tablist{
			transition: all 0.4s  ;
		}
		.tablist>div{
			text-align: center;
			line-height: 40px;
			border-bottom: 1px solid #ebebeb;
		}
		.selecta{
			color:#409eff;
		}
	}
</style>
